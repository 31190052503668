export const MULTILEVEL_CATEGORY_NAME = 'Nauczanie wielopoziomowe';
export const NOTIFICATION_DELAY_MS = 10000;
export const TABLET_WIDTH = 960;
export const MOBILE_WIDTH = 600;
export const MAX_LENGTH_PRODUCT_NAME = 45;
export const MAX_TEACHER_SUBJECT_COUNT = 5;
export const OTHER_SCHOOL_ID = 495;
export const BACK_URL_KEY_NAME = 'backTo';
export const API_REQUEST_DELAY = 2000;
export const ALARM_MESSAGE_ID = "2";
export const EVENT_NOTIFY_ID = "4";
export const NAVIGATION_EVENT_END_ID = 1;

export const PRODUCT_STATUS_VALUES = {
    NEW: 'new',
    OPENED: 'opened',
    FINISHED: 'finished',
}

export const PRODUCT_TYPE_VALUES = {
    TEACHER: 'teacher',
    STUDENT: 'pupil',
    STUDENT_OLD: 'pupil_basic',
    DEMO: 'demos',
    REGISTER: 'registers',
    HOMEWORK: 'homeworks',
    APP: 'app',
}


export const DATE_FORMAT = {
    FULL_WITH_TIME: 'dd.MM.yyyy HH:mm',
    DATE_ONLY: 'dd.MM.yyyy',
}

export const ORDER_VALUES = {
    PAGINATION_DEFAULT_SKIP_ELEMENTS: 0,
    PAGINATION_DEFAULT_TAKE_ELEMENTS: 10,
}

export const PROFILE_ROLE_ID = {
    TEACHER: 16,
    DIRECTOR: 33,
    STUDENT: 73,
    PARENT: 400,
    OTHER: 56,
}

export const EXTENDED = {
    ACTIVE: 'EXTENDED_ACTIVE',
    INACTIVE: 'EXTENDED_INACTIVE',
    ONLINE: 'EXTEND_ONLINE',
    PAPER: 'EXTEND_PAPER',
    PROCESSING: 'EXTENDED_PROCESSING',
}

export const TEACHER_SUBJECT_IDS = {
    MATH: 4,
    PHYSICS: 5,
    POLISH: 6,
    HISTORY: 7,
    BIOLOGY: 8,
    EARLY_EDU: 98,
    OTHER: 57,
}
